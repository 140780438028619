import request from './../request.js'

//accountRateList.vue
export function getAccountList() {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}
export function getSpeedList(params) {
    return request({
        url: "system/cardOperator/getSpeedList.do?",
        method: 'post',
        data: params
    })
}
export function insertSpeed(params) {
    return request({
        url: "system/cardOperator/insertSpeed.do?",
        method: 'post',
        data: params
    })
}
export function deleteSpeed(params) {
    return request({
        url: "system/cardOperator/delSpeed.do?",
        method: 'post',
        data: params
    })
}

//virtualCardList.vue
export function invCardList(params) {
    return request({
        url: "card/invList.do?",
        method: 'post',
        data: params
    })
}
export function cardList(params) {
    return request({
        url: "card/invList.do?",
        method: 'post',
        data: params
    })
}
export function findInvCardInfo(params) {
    return request({
        url: "card/findInvCardInfo.do?",
        method: 'post',
        data: params
    })
}

//usageStatistics.vue
export function getAccountFlowList(params) {
    return request({
        url: "invCard/getAccountFlowList.do?",
        method: 'post',
        data: params
    })
}
export function exportAccountFlowSync(params){  //导出表格
    return request({
        url: 'invCard/exportAccountFlowSync.do?',
        method: 'post',
        data:params
    })
}

//invNoRateList.vue
export function insertInvSpeed(params) {  //新增
    return request({
        url: "system/cardOperator/insertInvSpeed.do?",
        method: 'post',
        data: params
    })
}
export function getInvSpeedList(params) {  //列表
    return request({
        url: "system/cardOperator/getInvSpeedList.do?",
        method: 'post',
        data: params
    })
}
export function getAccountIdsByAc(params) {  //获取运营商
    return request({
        url: "system/cardOperator/getAccountIdsByAc.do?",
        method: 'post',
        data: params
    })
}
export function delInvSpeed(params) {  //删除
    return request({
        url: "system/cardOperator/delInvSpeed.do?",
        method: 'post',
        data: params
    })
}

